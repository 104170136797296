import DynamicPage from "./DynamicPage";
import Overview from "./Overview";

export const TreeDataSettings = [
    {
        "URL": "/", "PATH": "Alokitomon Blog/Life Lessons/Overview", "COMP": <Overview /> 
    },
    {
        "URL": "/khalaqa", "PATH": "Alokitomon Blog/Learn Quranic Vocabulary/Sound Verbs/Lesson 1 - خَلَقَ", "COMP": <DynamicPage bucket="www.alokitomon.com" folder="book" page="khalaqa"/>
    },
    {
        "URL": "/faaala", "PATH": "Alokitomon Blog/Learn Quranic Vocabulary/Sound Verbs/Lesson 2 - فَعَلَ", "COMP": <DynamicPage  bucket="www.alokitomon.com" folder="book" page="faaala"/>
    },
    {
        "URL": "/nasara", "PATH": "Alokitomon Blog/Learn Quranic Vocabulary/Sound Verbs/Lesson 3 - نَصَرَ", "COMP": <DynamicPage  bucket="www.alokitomon.com" folder="book" page="nasara"/>
    },
    {
        "URL": "/daraba", "PATH": "Alokitomon Blog/Learn Quranic Vocabulary/Sound Verbs/Lesson 4 - ضَرَبَ", "COMP": <DynamicPage  bucket="www.alokitomon.com" folder="book" page="daraba"/>
    },
    {
        "URL": "/alima", "PATH": "Alokitomon Blog/Learn Quranic Vocabulary/Sound Verbs/Lesson 5 - عَلِمَ", "COMP": <DynamicPage bucket="www.alokitomon.com" folder="book" page="alima"/>
    },
    {
        "URL": "/samia", "PATH": "Alokitomon Blog/Learn Quranic Vocabulary/Sound Verbs/Lesson 6 - سَمِعَ", "COMP": <DynamicPage  bucket="www.alokitomon.com" folder="book" page="samia"/>
    },
    
];