import { Link } from 'react-router-dom';
import { TreeDataSettings } from "./TreeDataSettings";
import { CheckCircleTwoTone, HeartTwoTone, SmileTwoTone } from '@ant-design/icons';

const TreeNode = {
    create: function (title, key, children) {
        return {
        title: title,
        key: key,
        value: key,
        icon: <HeartTwoTone twoToneColor="#eb2f96" />,
        children: children,
        isLeaf: false,
        };
    },
};

const TreeLeafNode = {
    create: function (title, key) {
        return {
        title: title,
        key: key,
        value: key,
        icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,        
        isLeaf: true,
        };
    },
};
  
function uniqueArray (a) {

    var unique = a.filter(function (item, pos) {
        return a.indexOf(item) === pos;
    })

    return unique;
}

function populateMatrix(pathList, uniqueNodes, matrix) {

    for (var i = 0; i < pathList.length; i++) {
        for (var j = 0; j < pathList[i].length -1 ; j++) {
            var posA = uniqueNodes.indexOf(pathList[i][j]);
            var posB = uniqueNodes.indexOf(pathList[i][j + 1]);
            matrix[posA][posB] = 1;
        }

    }

    return matrix;

}

function nameToSettingIndex(name, TreeDataSettings) {
    var pos = -1;
    var path ="";
    for (var i = 0; i < TreeDataSettings.length; i++) {
        path = TreeDataSettings[i].PATH;
        pos = path.indexOf(name);
        if (pos >= 0)
            return i;
    }
    return -1;
}
function findChildren(nodePos, pathMatrix, uniqueNodes, TreeDataSettings) {

    var nodes = [];

    for (var i = 0; i < uniqueNodes.length; i++) {
        if (pathMatrix[nodePos][i] === 1) {
            var node = null;
            var children = findChildren(i, pathMatrix, uniqueNodes, TreeDataSettings);
            
            if (children.length === 0 ) {                
                var titlePos = nameToSettingIndex(uniqueNodes[i], TreeDataSettings);                
                if (titlePos >= 0) {
                    var url = TreeDataSettings[titlePos].URL;
                    var name = uniqueNodes[i];
                    var title = <Link to={`${url}`}>{name}</Link>
                    var treeLeafNode = new TreeLeafNode.create (title,i);           
                    node = treeLeafNode;           
                    
                }
            }                
            else
            {
                node = new TreeNode.create (uniqueNodes[i],i,children);
                
            }
            nodes.push(node);
            
        }
    }

    return nodes;
    
}

export default function DynamicTreeData() {
    
    var st = TreeDataSettings;
    var allNodes = [];
    var uniqueNodes = [];
    var pathList = [];
    var compName = [];
    var compUrl = [];
    
    st.map((item, i) => {

        var path = item.PATH;
        var pathParts = path.split("/");
        pathParts.map((pp, j) => { allNodes.push(pp) });
        pathList.push(pathParts);
        compName.push = item.COMP;
        compUrl.push = item.URL

    });

    uniqueNodes = uniqueArray(allNodes);
       
    var matrix = new Array(uniqueNodes.length);

    for (var ii = 0; ii < uniqueNodes.length; ii++) {
        matrix[ii] = new Array(uniqueNodes.length);
    }

    for (var ii = 0; ii < uniqueNodes.length; ii++) {

        for (var jj = 0; jj < uniqueNodes.length; jj++) {
            matrix[ii][jj]= 0;
        }
        
    }

    var pathMatrix = populateMatrix(pathList, uniqueNodes, matrix)
    
    var treeData = TreeNode.create(uniqueNodes[0], 0, findChildren(0, pathMatrix, uniqueNodes, st)) ;

    var treeDataWithoutRoot = treeData.children;
    
    return treeDataWithoutRoot;
  }


