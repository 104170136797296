import React from 'react';
import {  Route, Routes } from 'react-router';
import { TreeDataSettings } from "./TreeDataSettings";
import Layout  from './Layout';

export default function DynamicRoute() {
    var st = TreeDataSettings;
    var listItems = st.map((item) => {
        return (<Route path={`${item['URL']}`} element= {item['COMP']} />);
    });

    return (       
        <Routes>            
            <Route path="/" element={<Layout />}>            
                {listItems}
            </Route>
        </Routes>
    );
    
}