import React, { useState } from 'react';
import { TreeSelect } from 'antd';
import DynamicTreeData from './DynamicTreeData';

export default function NavigationBarMobile() {
    
    const treeData = DynamicTreeData();
    
    const [value, setValue] = useState();
    const onChange = (newValue) => {
        setValue(newValue);
    };

    return (
        <TreeSelect          
          value={value}
          style={{ width: '100%', margin: '0 auto' }}
          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
          treeData={treeData}
          placeholder="Please select a page"
          treeDefaultExpandAll = {true}
          onChange={onChange}
          treeIcon={true}
        />
      );
    

}