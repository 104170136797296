import { Outlet } from "react-router-dom";
import NavigationBar from "./NavigationBar";
import NavigationBarMobile from "./NavigationBarMobile";

function Layout() {
    return (        
        <div className="container mx-auto">
            <div><h1 class="text-3xl px-4 py-4">Alokitomon Blog</h1></div>    
            <div class="flex flex-col xl:flex-row">           
                <div class="w-full xl:w-1/5">               
                    <div className="block xl:hidden w-full">
                        <div class="px-8 py-4">
                            <NavigationBarMobile />
                        </div>                    
                    </div>
                    <div className="hidden xl:block">                    
                        <div class="px-4 py-4">
                            <NavigationBar />
                        </div>
                    </div>
                </div>
                <div className="w-full xl:w-4/5">                    
                    <div class="px-8 py-4">
                        <Outlet />
                    </div>                    
                </div>
            </div>
        </div>);
}

export default Layout;



