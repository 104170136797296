import React, { useState, useEffect } from "react";
import Loading from "./Loading";

const DynamicPage = (props) => {  
  const [html, setHtml] = useState ("");
  const [loading, setLoading]  = useState(true);
  const bucketName = props.bucket;
  const folderName = props.folder;
  const pageName = props.page;

  const fetchPageData = (pageName) => {

    setLoading(true); // Set loading to true when initiating a fetch.
    
    fetch(`https://lvf3v7ha43.execute-api.us-east-2.amazonaws.com/pages?bucket=${bucketName}&folder=${folderName}&page=${pageName}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.text();
      })
      .then((data) => {
        setHtml(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);        
        setLoading(false); // Ensure loading is set to false in case of an error.
      });
  };

  // Initial fetch when the component mounts
  useEffect(() => {
    fetchPageData(pageName);
  }, [pageName]);

  return (loading ? (<Loading />): (
    <div className="static-html">
      <div dangerouslySetInnerHTML={{"__html": html}}></div>
    </div>)
    );
};

export default DynamicPage;
